import React from "react";
import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { Button } from "$/components/Button";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";

interface IProps extends IPageBaseProps {}

export const BookProgramme: React.FC<IProps> = () => {
  return (
    <Layout>
      <HtmlMeta title="Book Programme" />
      <div className="w-full flex flex-col justify-center items-center">
        <div className="flex flex-col text-center justify-center pt-4">
          <h1 className="text-4xl"> Book a 3 or 6 Session Coaching Programme</h1>
          <h3 className="font-normal">
            We created Learning Programmes to help you (and your team) reach your targets faster.
          </h3>
          <h3 className="font-normal">Here&apos;s how it works:</h3>
        </div>

        <div className="w-full flex justify-between pt-4 px-4 gap-4">
          <div className="rounded-2xl bg-white p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-2">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              1
            </div>
            <h2 className="max-w-[500px] text-xl mb-0">Tell us about you</h2>
            <p className="max-w-[500px] text-lg mb-0">
              You&apos;ll start by completing a short quiz: choose the options that feel closest to
              your needs right now.
            </p>
            <p className="max-w-[500px] text-lg mb-0">
              The questionnaire has been specifically designed to guide you through each step simply
              and seamlessly &ndash; setting you up with the programme best specifically designed to
              foster your growth, confidence and skills around your chosen topic.
            </p>
          </div>
          <div className="rounded-2xl bg-white p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-2">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              2
            </div>
            <h2 className="max-w-[500px] text-lg mb-0">Find the perfect coach</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Based on your responses, you&apos;ll have the chance to choose a coach from your top
              matches, or browse our full list of coaches.
            </p>
            <p className="max-w-[500px] text-lg mb-0">
              You can choose a coach based on who matches your preferences, of if squeezing it in
              during your busy schedule is more important, select coaches by availability. Your
              coach remains the same throughout your Programme unless you decide otherwise.
            </p>
          </div>

          <div className="rounded-2xl bg-white p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-2">
            <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
              3
            </div>
            <h2 className="max-w-[500px] text-lg mb-0">Start your programme</h2>
            <p className="max-w-[500px] text-lg mb-0">
              Book your preferred coach and time instantly.
            </p>
            <p className="max-w-[500px] text-lg mb-0">
              Your coach and you will discuss your needs during your programme, and adapt as needed
              &ndash; supporting you at every step.
            </p>
            <p className="max-w-[500px] text-lg mb-0">
              Through private 1:1 sessions, you&apos;ll discover growth opportunities &ndash;
              including your strengths, blind spots and what&apos;s holding you back, while offering
              much-needed consistency, motivation and accountability.
            </p>
          </div>
        </div>
        <div className="flex justify-center pt-6">
          <a href={"/enquiry"}>
            <Button
              primary
              onClick={() => Mixpanel.track(`Start Programme clicked`, { from: "Book Coaching" })}
              rightIcon={<ArrowRight size={18} />}
            >
              Start your Programme
            </Button>
          </a>
        </div>
      </div>
    </Layout>
  );
};
