import React from "react";
import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { ChoiceCard } from "./components/ChoiceCard";

interface IProps extends IPageBaseProps {}

export const BookSessionPage: React.FC<IProps> = () => {
  return (
    <Layout>
      <HtmlMeta title="Book a session" />
      <div className="w-full flex flex-col justify-center items-center">
        <div className="flex flex-col text-center justify-center pt-4">
          <h1 className="text-4xl"> Book Coaching</h1>
          <h2 className="font-normal">
            Find your next coaching session by selecting from one of the categories below.
          </h2>
        </div>
        <div className="w-3/4 flex justify-around pt-8">
          <ChoiceCard
            title="Coaching Programme"
            description="Best if you are new to coaching and want meaningful results. A series of 3 or 6 regular 45 minute sessions with a Coach, focusing on a specific goal or challenge."
            imageSrc="/images/Sponsor_checklist.svg"
            altText="Programme"
            href="/book-coaching/programme"
            favourite={true}
          />
          <ChoiceCard
            title="Single Session"
            description="A one off 45 minute session with a coach. Best if you have a specific coach you'd like to work with, or you'd like to explore different coaches and styles without the commitment."
            imageSrc="/images/Landing_Page.svg"
            href="/book-coaching/single-session"
            altText="Single Session"
          />
        </div>
      </div>
    </Layout>
  );
};
